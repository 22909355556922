import { Component, ViewEncapsulation } from '@angular/core';
import { LabelService } from 'src/app/shared/services/label.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  i18n = this.labelService.labels;
  constructor(private readonly labelService: LabelService) {
  }

}
