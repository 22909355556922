import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LabelService {

  labels!: any;
  constructor() { }

  setDataModel(data: any) {
    this.labels = data
  }

  getConfig() {
    return this.labels;
  }
  
}
