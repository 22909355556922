<owc-breadcrumb #breadcrumbElement class="custom-breadcrumb">
    <owc-breadcrumb-item 
        [routerLink]="['/']" 
        [hyperlinkProps]="{target:'_self'}" 
        (click)="$event.preventDefault();">
            <owc-typography  variant="badge">
                {{dashboard}}
            </owc-typography>
    </owc-breadcrumb-item>
    <owc-breadcrumb-item 
        *ngFor="let breadcrumb of breadcrumbs; let index = index,let last = last;" 
        [url]="breadcrumb.url"
        [hyperlinkProps]="{target:'_self'}" 
        (click)="$event.preventDefault();navigate(breadcrumb);" >
        <span *ngIf="!last"> 
            <owc-typography variant="badge">
                {{breadcrumb.label}}
            </owc-typography>
        </span>
        <span *ngIf="last"> 
            <owc-typography variant="caption">
                {{breadcrumb.label}}
            </owc-typography>
        </span>
    </owc-breadcrumb-item>
</owc-breadcrumb>