<app-header></app-header>

<div id="dashboard-content" class="dashboard-content" *ngIf="!isLoading">
  <owc-drawer #drawerElement [(ngModel)]="visible" hide-backdrop [mode]="'push'">
    <app-side-menu slot="panel">
    </app-side-menu>

    <div class="drawer-content">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </owc-drawer>
</div>

<div class="dashboard-spinner" *ngIf="isLoading">
  <owc-progress-spinner #progressSpinnerElement [size]="'xxLarge'" [value]="50" />
</div>

<app-footer></app-footer>