import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnclaveService } from 'src/app/shared/services/enclave.service';
import { LabelService } from 'src/app/shared/services/label.service';

@Component({
  selector: 'app-analytics-services-saved',
  templateUrl: './analytics-services-saved.component.html',
  styleUrls: ['./analytics-services-saved.component.scss']
})
export class AnalyticsServicesSaved {
  i18n = this.labelService.labels
  constructor(
    private readonly labelService: LabelService,
    private readonly router: Router,
    public readonly enclaveService: EnclaveService,
  ) { }
  reload() {
    this.router.navigate(['/config'])
      .then(() => {
        // window.location.reload();
        this.enclaveService.analyticsServicesUpdated.next(true);
      });
  }
}
