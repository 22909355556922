import { Component } from '@angular/core';
import { NavifyService } from '../../services/navify.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  constructor(
    private readonly navifyService: NavifyService,
  ) { }

  ngOnInit() {
    this.navifyService.logOut()
  }
}
