import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[RocheEmailValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: RocheEmailValidator,
    multi: true
  }]
})
export class RocheEmailValidator implements Validator {

  emailValidator:RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  domainList = [
    '@contractors.roche.com',
    '@businesspartners.roche.com',
    '@roche.com'
  ]
  validate(control: AbstractControl) : {[key: string]: boolean} | null {
    if (control.value && !this.emailValidator.test(control.value)) {
      return { 'invalidEmail': true };
    }
    if (control.value && this.domainList.find((elm:string)=>control.value.endsWith(elm))  === undefined){
      return { 'nonRocheEmail': true };
    }
    return null;
  }
}