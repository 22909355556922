import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { 
  FileProgressGuard 
} from 'projects/enclave-studies/src/app/guards/file-progress.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { 
    path: 'logout',
    canActivate: [FileProgressGuard],
    component: LogoutComponent 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
