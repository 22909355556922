
<div class="confirm-popup padd-16"  *ngIf="data">
    <owc-modal-dialog #owcConfigModelDialog disable-backdrop-click [visible]="true">        
        <owc-component-header class="modal-header">
            {{data.HEADER}}
        </owc-component-header>
         <div class="modal-content" class="padd-16" [formGroup]="studyCreateForm">            
            <owc-input
            #inputElement
            [cols]="30"
            [rows]="3" 
            class="owc-input-width"
             [maxlength]="data.MAX_LENGTH"             
             [variant]="'outlined'" 
             formControlName="studyName" [validity]="studyCreateForm.get('studyName')?.invalid && 
             (studyCreateForm.get('studyName')?.dirty ||
             studyCreateForm.get('studyName')?.touched ||
             studyCreateForm.get('studyName')?.errors?.['pattern'])
             ? { state: 'error' }
             : { state: 'valid' }
             " >
             <owc-assistive-text
                *ngIf="studyCreateForm.get('studyName')?.invalid &&
                    (studyCreateForm.get('studyName')?.dirty ||
                    studyCreateForm.get('studyName')?.touched || 
                    studyCreateForm.get('studyName')?.errors?.['pattern'])">
                <span *ngIf="studyCreateForm.get('studyName')?.errors?.['required']">
                    {{data?.ERRORMSG?.REQUIRED_FIELD}}
                </span>
                <span *ngIf="studyCreateForm.get('studyName')?.errors?.['maxlength']">
                    {{data?.ERRORMSG?.MAX_LENGTH}}
                    </span>
                <span *ngIf="studyCreateForm.get('studyName')?.errors?.['pattern']"> 
                        {{data?.ERRORMSG?.ASSISTIVE_TEXT_STUDY_NAME_PATTERN}}
                </span>                                                                                   
            </owc-assistive-text>
        </owc-input>   
        <div class="padd-left-16">{{data.VALIDATION_TEXT }}</div>         
        </div> 
        <owc-component-footer slot="footer" class="input-modal-footer">
            <owc-button (click)=" onDecline()" [variant]="'secondary'" class="footer-btn-align">
                {{data.CANCEL_BTN}}</owc-button>
            <owc-button (click)="onAddFieldKey()"  class="margin-left-25">
                 {{ data.CONFIRM_BTN}}</owc-button>
        </owc-component-footer>
    </owc-modal-dialog>
</div>