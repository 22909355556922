import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthSession } from '@navify-platform/auth';
import { Components } from '@one/web-components';
import { NavifyService } from 'src/app/auth/services/navify.service';
import { LabelService } from 'src/app/shared/services/label.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @ViewChild('tooltipElementProfile', { static: true })
  tooltipElementProfile!: Components.OwcTooltip;

  i18n = this.labelService.labels;
  displayName!: string;
  sessionInfo!: AuthSession;

  constructor(
    private readonly nanvifyService: NavifyService,
    private readonly router: Router,
    private readonly labelService: LabelService
  ) { }

  ngOnInit() {
    this.sessionInfo = this.nanvifyService.sessionInfo
  }

  logout(){
    this.router.navigate(['logout'])
  }

  visibleChangeHandler(event: CustomEvent){
    if(event.detail){
      this.tooltipElementProfile.visible = false
    }
  }

}
