import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { environment as env} from 'src/environments/environment';
import { Enclave, EnclaveResponse, WBS } from '../models/enclave';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnclaveService {
  enclaveDetails!: Enclave;
  readonly schemaSelected = new BehaviorSubject<string>("");
  readonly schemaUploaded = new BehaviorSubject<boolean>(false);
  readonly uploadedfileName = new BehaviorSubject<string>("");
  readonly fileUploadInProgress = new BehaviorSubject<number>(0);
  readonly analyticsServicesUpdated = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly configService: ConfigService,
    private http: HttpClient
  ) { }

  getEnclaveDetails(){
    const url = this.configService.config['domain'] + env.enclaveDetails
    return this.http.get<EnclaveResponse>(url);
  }

  saveEnclaveDetails(id: string, payload: Enclave){
    const url = this.configService.config['domain'] + env.enclavePutAPI + '/' + id
    return this.http.put<EnclaveResponse>(url, payload);
  }

  isConfigured(){
    return this.enclaveDetails.enclaveStatus.toLowerCase() 
                    === env.ENC_CONF.STATUS_CONFIGURED.toLowerCase() 
  }

  updateUser() {
    const url = this.configService.config['domain'] + env.updateUser 
    return this.http.post(url, {});
  }

  uploadCustomizedMetadataSchema(formData : any, headers: any) {
    const url = this.configService.config['domain'] + env.fileUploadForMetadata
    return this.http.post<any>(url, formData, { headers: headers });
  }

  saveWBSSelectionAndS3BucketSetting(payload: WBS) {
    const url = this.configService.config['domain'] + env.instarnceSize
    return this.http.post<WBS>(url, payload);
  }

  getWBSSelectionAndS3BucketSetting(placeholder:string) {
    const url = this.configService.config['domain'] + env.instarnceSize + "/" + placeholder + "/" + "contents"
    return this.http.get<any>(url);
  }

}
