import { Component } from '@angular/core';
import { NavifyService } from '../../services/navify.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthLoginReturn } from '@navify-platform/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private readonly navifyService: NavifyService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  async ngOnInit() {
    await this.checkAuth()
  }

  async checkAuth() {
    const isLoggedIn = await this.navifyService.isLoggedIn()
    if (isLoggedIn) {
      const loginReturn: AuthLoginReturn = await this.navifyService.getLoginReturn()
      if (loginReturn !== null) {
        this.router.navigate([loginReturn.state.url])
      }
      else {
        this.router.navigate([''])
      }
    }
    else {
      const param1 = this.route.snapshot.queryParams["returnUrl"]
      const url = param1 || ''
      this.navifyService.loginToNavify(url)
    }
  }
}
