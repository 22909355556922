import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { CoreModule } from '../core/core.module';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';


@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    ConfirmationPopupComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    CoreModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule { }
