import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Components } from '@one/web-components';

interface DialogData {
  HEADER: string,
  DESC: string[],
  CONFIRM_BTN?: string,
  CANCEL_BTN?: string,
  FOOTER_TEXT?: string,
  VALIDATION_TEXT?: string
}

@Component({
  selector: 'app-confirmation-popup-without-spinner',
  templateUrl: './confirmation-popup-without-spinner.component.html',
  styleUrls: ['./confirmation-popup-without-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationPopupWithoutSpinnerComponent {
  @ViewChild('typographyElement', { static: true }) typographyElement!: Components.OwcTypography;
  @ViewChild('progressSpinnerElement', { static: true }) progressSpinnerElement!: Components.OwcProgressSpinner;
  studyPattern = "^[a-zA-Z0-9]*$";
  studyCreateForm = new FormGroup({    
      studyName: new FormControl(''),
    })
  
  visible: boolean = true;
  constructor(@Inject(DIALOG_DATA) protected data: DialogData, public dialogRef: DialogRef<boolean>) {     
   }
  onAccept() {
    this.dialogRef.close(true);
  }
  onDecline() {
    this.dialogRef.close(false);
  }

}
