import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytesToMB'
})
export class BytesToMBPipe implements PipeTransform {
  byte: string = '1024';
  transform(value:number) {
     return this.getReadableFileSizeString(value);
  }

  getReadableFileSizeString(fileSizeInBytes:number) {
    let i = -1;
    const byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes /= Number(this.byte);
      i++;
    } while (fileSizeInBytes > Number(this.byte));
  
    return Math.max(fileSizeInBytes, 0.0).toFixed(1) + byteUnits[i];
  }

}
