import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationCancel, Router, ActivatedRoute, PRIMARY_OUTLET, Params } from '@angular/router';
import { filter, tap, distinctUntilChanged } from 'rxjs';
import { Location } from '@angular/common';

interface StateData{
  data : {
    [k: string] : string
  }
}

interface BreadcrumbInterface {
  label: string;
  params: Params;
  url: string;
  state: StateData | null
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {
  dashboard: string = 'Dashboard';
  public breadcrumbs: BreadcrumbInterface[];
  public dashboaordData: Partial<BreadcrumbInterface> = {
    url: 'm/applobby'
  }
  navigationEvent = this.router.events;
  navigationEventEnd = this.navigationEvent.pipe(
    filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel),
    tap(() => { this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root); })
  );

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location
  ) {
    this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root);
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged()
    ).subscribe((event) => {
      this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root)
    })
  }

  // /**
  //  * Recursively build breadcrumb according to activated route.
  //  * @param route
  //  * @param url
  //  * @param breadcrumbs
  //  */
  // buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
  //   let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data['breadcrumb'] : '';
  //   let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
  //   return []
  // }

  private getBreadcrumbs(

    route: ActivatedRoute, url: string = '',
    breadcrumbs: BreadcrumbInterface[] = []): BreadcrumbInterface[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const DYNAMIC_BREADCRUMB_KEY = 'DYNAMIC_BREADCRUMB_KEY'

    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      if (!child.snapshot?.data?.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      url += `/${routeURL}`;

      const details = this.breadcrumbDetails(child)
      const label = details.label;
      const key = details.key;
      const data = details.data;

      const breadcrumb: BreadcrumbInterface = {
        label,
        params: child.snapshot.params,
        url,
        state: data
      };
      if (breadcrumb.label) {
        breadcrumbs.push(breadcrumb); 
      }
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return [];
  }


  breadcrumbDetails(child: ActivatedRoute) {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const DYNAMIC_BREADCRUMB_KEY = 'DYNAMIC_BREADCRUMB_KEY';
    let label = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
    let key;
    let data: StateData | null = null;
    if (child.snapshot.data.hasOwnProperty(DYNAMIC_BREADCRUMB_KEY)) {
      if(this.location.getState() !== undefined){
        data = this.location.getState() as StateData ?? null;
      }
      key = child.snapshot.data[DYNAMIC_BREADCRUMB_KEY];
      if(data && data.data){
        label = data.data[key];
      }
    }
    return { label, key, data }
  }
  

  navigate(breadcrumb: Partial<BreadcrumbInterface>) {
    let data;
    if(breadcrumb.state !== null){
      this.router.navigate([breadcrumb.url], {
        state: { data }
      })
    }
    else {
      this.router.navigate([breadcrumb.url])
    }
  }


}
