import { Injectable } from '@angular/core';

interface Config {
  [k: string] : string
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config!: Config;
  constructor() { }

  setConfig(data: Config) {
    this.config = data
  }

  getConfig() {
    return this.config;
  }

}