import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { NavifyService } from '../services/navify.service';


@Injectable({
  providedIn: 'root'
})
export class AppPermissionService {
  constructor(private readonly navifyService: NavifyService,
    private readonly router: Router
  ) { }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const isAllowed = await this.navifyService.checkPermission()
    if (isAllowed === false) {
      this.router.navigate(['u'])
    }
    return isAllowed
  }
}

export const appPermissionGuard: CanActivateFn = (route, state) => {
  return inject(AppPermissionService).canActivate(route, state);
};
