import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { NavifyService } from '../services/navify.service';


@Injectable({
  providedIn: 'root'
})
export class AuthguardService {
  constructor(private readonly navifyService: NavifyService,
    private readonly router: Router
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = await this.navifyService.isLoggedIn()
    if (isLoggedIn === false) {
      this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } })
    }
    return isLoggedIn
  }
}

export const AuthGuard: CanActivateFn = (route, state) => {
  return inject(AuthguardService).canActivate(route, state);
};
