import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, ViewEncapsulation } from '@angular/core';

interface DialogData {
  HEADER: string,
  DESC: string[]
  CONFIRM_BTN: string,
  CANCEL_BTN: string
}

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationPopupComponent {

  visible: boolean = true;
  constructor(@Inject(DIALOG_DATA) protected data: DialogData, public dialogRef: DialogRef<boolean>) { }

  onAccept() {
    this.dialogRef.close(true);
  }
  onDecline() {
    this.dialogRef.close(false);
  }
}
