<div class="container">
    <div class="info">
        <img alt="enclave-saved" src="/assets/images/enclave_saved.svg" />
        <owc-typography #typographyElement>
            {{i18n.CONFIG.EDIT_CONFIG_FLOW.CHANGE_ANALYTICS_SAVE_POP_UP_TEXT}}
        </owc-typography>
        <owc-button #buttonElement (click)="reload()">
            {{i18n.CONFIG.EDIT_CONFIG_FLOW.CHANGE_SCHEMA_SAVE_POP_UP_CLOSE_BTN}}
        </owc-button>
    </div>
</div>