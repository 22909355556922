import { Directive, Input, HostListener, ElementRef, OnInit, OnDestroy, Renderer2,  ViewContainerRef, ComponentRef } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OwcAssistiveText, OwcInput } from '@one/angular';
import { InputValidity, } from '@one/web-components';

const TIMEOUT = 200;

@Directive({
  selector: '[appFormControlValidationMsg]'
})
export class FormControlValidationMsgDirective implements OnInit, OnDestroy {

  constructor(private readonly elRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly control: NgControl,
    public vcRef: ViewContainerRef,
  ) { }

  errorSpanId: string = '';
  erroText: string = ''
  statusChangeSubscription!: Subscription | undefined;
  compRef!: ComponentRef<OwcAssistiveText>;
  defaultMessages: { [k: string]: string } = {
    default: "",
    required: 'Field cannot be empty'
  };


  private _appFormControlValidationMsg: undefined | { [k: string]: string } = {};

  @Input() get appFormControlValidationMsg() {
    return this._appFormControlValidationMsg;
  }
  set appFormControlValidationMsg(value) {
    this._appFormControlValidationMsg = value || {};
  }


  ngOnInit(): void {
    this.defaultMessages = { ...this.defaultMessages, ...this._appFormControlValidationMsg }
    this.compRef = this.vcRef.createComponent(OwcAssistiveText)
    this.renderer.setAttribute(this.compRef.location.nativeElement,'slot','assistive-text' )
    this.renderer.appendChild(this.elRef.nativeElement, this.compRef.location.nativeElement)

    this.statusChangeSubscription = this.control.statusChanges?.subscribe((status) => {
      const InputEl = this.elRef.nativeElement 
      if (status !== 'VALID' && (this.control.touched || this.control.dirty)) {
        InputEl.validity = { 'state': 'error' }
      }
      else {
        InputEl.validity = { 'state': 'valid' }
      }
      this.updateAssistiveText()
    })

    setTimeout(() => {
      this.updateAssistiveText()
    }, TIMEOUT);
  }

  ngOnDestroy(): void {
    this.statusChangeSubscription?.unsubscribe();
  }

  @HostListener('blur', ["$event"])
  @HostListener('input', ["$event"])
  handleBlurEvent() {
    if (this.control.value === null || this.control.value === '') {
      if (this.control.errors) this.showError();
      else this.removeError();
    }
    this.showError()
  }

  private showError() {
    let state: InputValidity = { state: 'valid' }
    const valErrors: ValidationErrors | null = this.control.errors;
    if (valErrors && Object.keys(valErrors).length !== 0) {
      state = { state: 'error'}
    }
    const testEl = this.elRef.nativeElement 
    testEl.validity = state
    this.updateAssistiveText()
  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) errorElement.remove();
  }

  updateAssistiveText() {
    const valErrors: ValidationErrors | null = this.control.errors;
    const firstError = valErrors ? Object.keys(valErrors)[0] : 'default'
    this.compRef.location.nativeElement.innerText = this.defaultMessages[firstError]
  }

}