<div class="container">
    <div class="info">
        <img alt="enclave-saved" src="/assets/images/study_created.svg" />
        <owc-typography  [variant]="'title5'">
            {{ i18n.STUDY_SAVED.CONFIGURED_SUCCESSFULLY}}
        </owc-typography>
        <owc-typography >
            {{ i18n.STUDY_SAVED.DISPLAY_TEXT }}
        </owc-typography>
        <owc-button (click)="studySaved()">
            {{ i18n.STUDY_SAVED.BTN_TEXT }}
        </owc-button>
    </div>
</div>