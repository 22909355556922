import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { Auth, AuthConf, AuthLoginReturn, AuthLogoutReason, AuthLogoutReturn, AuthProfile, AuthSession } from '@navify-platform/auth';
import { Env } from '@navify-platform/env';
import { Idle, IdleEvent, IdleEventType, IdleOptions } from '@navify-platform/idle';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ConfirmationPopupComponent } from '../components/confirmation-popup/confirmation-popup.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LabelService } from 'src/app/shared/services/label.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NavifyService {
  arrYesNoCont: string[] = [];
  i18n = this.labelService.labels;
  sessionInfo!: AuthSession;
  authEnv!: Env;
  authConf!: AuthConf;
  auth!: Auth;
  idle!: Idle;
  userUuid!: string;
  readonly isIdle = new BehaviorSubject<boolean>(false);
  $isIdle = this.isIdle.asObservable();
  emailOfUserLoggedIn = new BehaviorSubject<string>('');
  emailID!: string;
  

  constructor(private readonly configServcie: ConfigService,
    private readonly dialog: Dialog,
    private readonly http: HttpClient,
    private readonly labelService: LabelService
  ) { 
      this.navifyAuthConfig()
      this.initiateIdle()
  }

  navifyAuthConfig() {
    const env = new Env({
      props: {
        platformApiUrl: this.configServcie.getConfig()['platformApiUrl'],  // Navify Platform URL
        authUiUrl: this.configServcie.getConfig()['authUiUrl'],       // Authentication application URL
        appAlias: this.configServcie.getConfig()['appAlias'],        // Client application alias
        tenantAlias: this.configServcie.getConfig()['tenantAlias'],     // Client tenant alias
      },
    });

    this.authConf = {
      env,                                 // Env library instance
      sessionExpirationBeforeTime: 60,  // (1 min) How much time before expiration should the library emit the BEFORE_SESSION_EXPIRATION event (milliseconds)
      sessionPollingIntervalTime: 600,  // (10 mins) How often should the library send a polling request to check the existing session (milliseconds)
      sessionAutoRefresh: true,           // Flag deciding whether to refresh session automatically before session expiration
      redirectUrlUseHash: false,           // Flag deciding whether to use URL hash instead of query parameters
      identificationFormSkip: false,       // Flag deciding whether to force skip the identification step
      identificationFormSubmit: false,     // Flag deciding whether to submit the identification step if username is provided
      customPageTitle: 'Enclave',              // Page title customization HTML
      customBackgroundStyle: "url('${landingPage}') center no-repeat / cover",        // Background style customization CSS
      customHeader: 'Enclave',                 // Header customization HTML
      customHeadline: '',               // Headline customization HTML
      customCaption: '',                // Caption customization HTML
      customFooter: "<div class=''><a href='http://roche.com'>Go to Roche site</a></div>",                 // Footer customization HTML
      i18n: false,                         // Flag deciding whethet to internationalize the UI
      theme: 'navify2023'
    };

    const idleOptions: IdleOptions = {  
      env: env,                             // environment, NavifyPlatformEnv instance
      syncTimeInterval: Number('1000') * Number('60') * Number('10'),     // clock sync interval time (optional)
      tickTimeInterval: Number('1000') * Number('60') * Number('1'),          // clock tick interval time (optional)
      idleTimeout: 1000 * 60 * 60,           // Idle timeout value override (optional)
      beforeIdleTimeSpan: 1000 * 60 * 1,        // how much time before idle the event is triggered (optional)
      toggleSyncActivity: true,             // flag deciding whether to synchronize activity information via API
      toggleTracking: true,                 // flag deciding whether to turn on DOM events tracking
    };

    this.auth = new Auth(this.authConf)
    this.idle = new Idle(idleOptions)
  }

  async navifyAuthInit() {
    await this.auth.init()
  }

  async getAuthSession() {
    return await this.auth.getSession();
  }

  async getLoginReturn() {
    return await this.auth.getLoginReturn();
  }

  async isLoggedIn() {
    await this.auth.init();
    const loginOutput: AuthLoginReturn = await this.auth.getLoginReturn();
    const session: AuthSession = await this.auth.getSession();
    if ( session !== null) {
    // if (loginOutput !==null && session !== null) {
      this.sessionInfo = session;
      this.emailID = this.sessionInfo.profile.username;
      this.emailOfUserLoggedIn.next(this.emailID);
      this.checkPermission();
      return true
    }
    else {
      return false
    }
  }

  getProfile(){
    const PlatformApiURL = this.configServcie.getConfig()['platformApiUrl']
    const profileURL = environment.apiProfile
    return new Promise((resolve, reject) => {
      this.http.get(PlatformApiURL + profileURL).subscribe({
        next: (data: any) => {
          this.userUuid = data.userUuid
          resolve(data)
        },
        error: (data) => {
          resolve(null)
        }
      })
    })
  }

  async checkPermission() {
    const tenantAlias = this.configServcie.getConfig()['tenantAlias'];
    // const aliasDetails = data.tenants.filter( (elm: any) => elm.name === tenantAlias)
    const navifyData:any = await this.getProfile()
    const aliasDetails = navifyData.tenants.filter( (elm: any) => elm.name === tenantAlias)
    if(aliasDetails.length !== 0){
      return true
    } 
    else{
      return false
    }

  }

  async loginToNavify(redirectSubRoute: string) {
    await this.auth.init();
    await this.auth.loginRedirect({
      returnTo: window.location.href, // Login return URL
      username: '',
      state: {
        url: redirectSubRoute
      }
    });
  }

  async logOut(reason: AuthLogoutReason = AuthLogoutReason.userAction ){
    await this.auth.init();
    await this.auth.logoutRedirect({
      returnTo: window.location.origin,   // Logout return URL
      state: {},       // Preserved client state
      reason: reason,      // Logout reason
      logMessage: reason,  // Logout log message (audit)
    });   
    const output: AuthLogoutReturn = await this.auth.getLogoutReturn();
    if (output !== null) {    
      await this.auth.destroy();
      await this.idle.destroy();
      sessionStorage.clear();
      localStorage.clear();
    }
  }

  async initiateIdle(){
    await this.idle.init();
    this.idle.subscribe(async (event: IdleEvent) => {
      this.idleEventHandler(event)
    });
  }

  async idleEventHandler(event: IdleEvent) {
    if (IdleEventType.BeforeIdle === event.type) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent,
        {
          data: this.i18n.IDLE_POPUP
        }
      )
      dialogRef.closed.subscribe(async (res)=>{
        if(res){
          this.auth.init()
          this.idle.init()
          this.idle.markActivity()
        }
        else{
          await this.logOut()
        }
      })
    }
    if (IdleEventType.Idle === event.type) {
      await this.logOut()
    }
  }

  async confirmIdleAction(idleOut: string) {
    this.arrYesNoCont.push(idleOut);
    await this.auth.init();
    await this.idle.init();
    if (idleOut === 'idle_yes' && this.arrYesNoCont.length === 1) {
      await this.idle.markActivity();
    }
    else if (idleOut === 'idle_no' && this.arrYesNoCont.length === 1) {
      await this.logOut();
    }
  }


}
