import { CanActivateFn } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { EnclaveStudyDetailsService } from '../services/enclave-study-details.service';
import { 
  ConfirmationPopupWithoutSpinnerComponent 
} from 'src/app/shared/component/confirmation-popup-without-spinner/confirmation-popup-without-spinner.component';
import { LabelService } from 'src/app/shared/services/label.service';

interface FileListObj {
  file: File
  name?: string
  size?: number
  path?: string
  status?: string
  lastModified?: number;
  userUuid?: string;
  projectKey?: string;
  chunkPercents: {
      [key: number]: number
  }
  fileProgress?: number
}

@Injectable({
providedIn: 'root'
})
export class FileProgressGuardService {
  i18n = this.labelService.labels
constructor(
  private readonly studyDetailsService: EnclaveStudyDetailsService,
  private readonly dialog: Dialog,
  private readonly labelService: LabelService,
) { }
async canActivate(): Promise<boolean> {
  const fileProgressList: FileListObj[] = Object.values(this.studyDetailsService.dataIngressFileUploadData)
  const filteredData = fileProgressList.filter((fd) => fd['status'] === 'INP')

  if (filteredData.length !== 0) {
      const dialogRef = this.dialog.open(ConfirmationPopupWithoutSpinnerComponent, {
          data: this.i18n.INGRESS.LOGOUT_POPUP
      })
      const result: boolean = await new Promise((resolve, reject) => {
          dialogRef.closed.subscribe(res => {
              resolve(res as boolean)
          });
      });

      return result

  }
  else {
      return true

  }
}
canMatch(): boolean {
  return true;
}
}

export const FileProgressGuard: CanActivateFn = (route, state) => {
  return inject(FileProgressGuardService).canActivate();
};
