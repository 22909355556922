import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './shared/services/config.service';
import { LabelService } from './shared/services/label.service';

interface Env {
  [k: string]: string
}

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  private readonly CONFIG_URL = 'assets/config/config.json';
  private readonly LABEL_URL = 'assets/i18n/i18n.en.json';
  private httpPrivateClient: HttpClient
  constructor(
    private http: HttpClient,
    private http_handler: HttpBackend,
    private configService: ConfigService,
    private labelService: LabelService
  ) {
    this.httpPrivateClient = new HttpClient(http_handler);
  }


  loadConfiguration() {
    return new Promise<void>((resolve, reject) => {
      this.httpPrivateClient.get<Env>(this.CONFIG_URL).subscribe(({
        next: (response: Env) => {
          this.configService.config = response
          resolve();
        }
      }))
    });
  }

  loadLabels() {
    return new Promise<void>((resolve, reject) => {
      this.httpPrivateClient.get(this.LABEL_URL).subscribe(({
        next: (response) => {
          this.labelService.labels = response
          resolve();
        }
      }))
    });
  }

}
