<div class="side-menu">
    <owc-side-menu #sideMenuElement [class]="menuExpanded ? 'expanded' : 'collapsed'">
        <owc-component-header slot="header" separator class="sm-header sm-list-item">
            <owc-icon-button
                *ngIf="!menuExpanded"
                class="sm-icon" slot="prefix" [size]="'small'" icon="menu" (click)="toggleSideMenu(true)"
                family="outlined"></owc-icon-button>
            <owc-icon-button 
                *ngIf="menuExpanded"
                class="sm-icon" slot="prefix" [size]="'small'" icon="clear" (click)="toggleSideMenu(false)"
                family="outlined"></owc-icon-button>
        </owc-component-header>
        <owc-list #listElement no-border [size]="'medium'" (selectedChange)="onSelectedChange($event)" 
        [selected]="selectedMenu">
            <ng-container *ngFor="let menu of menuItems; let i = index" >
            <owc-list-item [variant]="'side-menu'" 
                class="sm-list-item" 
                [routerLink]="menu.route"
                [disabled]="menu.disabled"
                [value]="menu.name"
                *ngIf="menu.display"
                >
                    <owc-icon 
                        [ngClass]="{'sm-item-disabled': !(menu.disabled) === false}" 
                        class="sm-icon" 
                        slot="prefix" 
                        family="outlined" 
                        name="{{menu.icon}}">
                    </owc-icon>
                    <span class="sm-text">{{menu.name}} </span>
            </owc-list-item>
            </ng-container>
        </owc-list>
        <owc-divider />
        <owc-footer #footerElement fix-bottom>
            <!-- <owc-divider />
            <owc-list no-border>
                <owc-list-item>
                    <owc-icon slot="prefix" family="outlined" name="{{i18n.SIDE_MENU.CHAT_ICON}}"></owc-icon>
                    {{i18n.SIDE_MENU.CHAT}}
                </owc-list-item>
            </owc-list> -->
        </owc-footer>
    </owc-side-menu>
</div>