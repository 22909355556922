<div class="lobby">
    <owc-typography variant="title5" class="lobby-title">
        {{i18n.APP_LOBBY.WELCOME }} <span>{{ sessionInfo.profile.displayName }}</span>
    </owc-typography>

    <owc-grid [columns]="12" [spacing]="'24'">
        <owc-grid-item xs="6" sm="6" md="6" lg="6" xl="6">
            <owc-card class="lobby-card">
                <owc-component-header slot="header" class="card-title">
                    <owc-typography #typographyElement device="desktop" [variant]="'button'">
                        {{i18n.APP_LOBBY.CARD_1.LBL}}
                    </owc-typography>
                </owc-component-header>

                <div class="lobby-card--body">
                    <owc-typography variant="body2" device="desktop"
                        *ngFor="let item of i18n.APP_LOBBY.CARD_1.DESC">
                            {{item}}
                            <br /><br />
                    </owc-typography>
                </div>
            </owc-card>
        </owc-grid-item>

        <owc-grid-item xs="6" sm="6" md="6" lg="3" xl="3">
            <owc-card class="lobby-card">
                <owc-component-header slot="header">
                    <owc-typography #typographyElement device="desktop" [variant]="'button'">
                        {{i18n.APP_LOBBY.CARD_2.LBL}}
                    </owc-typography>
                </owc-component-header>

                <div class="lobby-card--body">
                    <ng-container>
                        <owc-hyperlink *ngFor="let link of links"  variant="body2" class="lobby-card--link" device="desktop">
                            <owc-typography device="desktop" [variant]="'button'">
                                {{ link }}
                            </owc-typography>
                        </owc-hyperlink>
                    </ng-container>
                </div>
            </owc-card>
        </owc-grid-item>

        <owc-grid-item xs="6" sm="6" md="6" lg="3" xl="3">
            <owc-card class="lobby-card">
                <owc-component-header slot="header">

                    <owc-typography variant="title6" device="desktop">
                        {{i18n.APP_LOBBY.CARD_3.LBL}}
                    </owc-typography>
                </owc-component-header>

            </owc-card>
        </owc-grid-item>
    </owc-grid>



</div>