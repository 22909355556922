
<!-- Without spinner -->
<div class="confirm-popup-without-spinner">
    <owc-modal-dialog #modalDialogElement disable-backdrop-click [visible]="true">
        <div class="modal-content">
            <owc-typography class="heading">
                {{data.HEADER}} 
            </owc-typography>
            <owc-typography class="desc" *ngFor="let para of data.DESC; let i = index">
                {{ para }} 
            </owc-typography>
        </div>
        <owc-component-footer slot="footer" >
            <div slot="suffix" class="modal-footer">
            <owc-button  (click)=" onDecline()" [variant]="'secondary'">
                {{data.CANCEL_BTN}}
            </owc-button>
            <owc-button (click)="onAccept()" class="margin-left-16"> {{ data.CONFIRM_BTN}}</owc-button>
            </div>
        </owc-component-footer>
    </owc-modal-dialog>
</div>
