import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LabelService } from 'src/app/shared/services/label.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
  i18n = this.labelService.labels;
  message: string  = this.i18n.UN_AUTH.MSG_1;
  constructor(
    private readonly labelService: LabelService,
    private readonly route : Router,
  ) {

  }
  ngAfterViewInit() {
    if(this.route.url.includes('u1')){
      let msg = this.i18n.UN_AUTH.MSG_2;      
      msg = msg.replace('ENC_NAME', this.i18n.APP_TITLE_2)
      this.message = msg;
    }
  }

}
