import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly configService: ConfigService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: { 'X-Navify-Tenant': this.configService.getConfig()['tenantAlias'] },
      withCredentials: true
    });
    return next.handle(request);
  }
}
