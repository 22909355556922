import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment as env } from 'src/environments/environment';
import { AddExistingMemberPayload, DeleteMemberApi, EditMemberApi, EditMemberPayload, EditRoleApi, ExistingMemberApi, InviteNewMemberApi, MemberListApi,StudyBaseMemberListApi, type InviteNewMemberPayload } from '../models/study';
import {
  BucketDetailsResponse,
  InfraDetailsResponse,
  PositDetailsResponse
} from '../models/analytics';
import { 
  cloud9Response, 
  DataIngressResponse, 
  DataSet, 
  DatasetID, 
  DataSetPayload, 
  EC2InstanceSize, 
  GenerateKey, 
  GetJupyterInstance, 
  HTCFileSize, 
  HTCFNodeInstance, 
  HTCInstance, 
  IngressFiles, 
  IngressFilesResponse, 
  IngressResponse, 
  JupyterInstancePayload, 
  MetaDataPayLoad, 
  MetaDataResponse, 
  NftPayLoad, 
  SageMakerProfile, 
  SageMakerResponse, 
  Schema, 
  SchemaResponse, 
  sFTPURL} from '../models/ingress';

@Injectable({
  providedIn: 'root'
})
export class EnclaveStudyDetailsService {
  masterSchema!: Schema;
  metaDataValidality = new BehaviorSubject(true);
  saveExitOrNextBtnClickInMetadata = new BehaviorSubject(false);
  isLoading: boolean = false;
  dataIngressFileUploadData = {};
  selectedCatalogue!: any;
  constructor(
    private readonly configService: ConfigService,
    private http: HttpClient
  ) { }

  filterIngressByStatus(payload: DataSetPayload,nextToken:string) {
    const url = this.configService.config['domain'] + env.ingressApi;
    const params = {
      status: payload.status,
      studyKey: payload.studyKey,
      size: 100,
      nextToken: nextToken
    }
    return this.http.get<DataIngressResponse>(url , { params: params });
  }

  filterInboxByStatus(status: string | number, nextToken: string) {
    const category = 'DATASET-SUBMIT';
    const url = this.configService.config['domain'] + env.inbox +'/'+category;
    const params = new HttpParams()
      .set('size', '100')
      .set('nextToken', nextToken)
      .set('status', status)
    return this.http.get<IngressResponse>(url, { params: params });
  }

  saveInboxByStatus(requestPayLoad: any) {   
    const url = this.configService.config['domain'] + env.inbox ;
    return this.http.put<any>(url, requestPayLoad);
  }

  createDataSet(payload: DataSet) {
    const url = this.configService.config['domain'] + env.dataset;
    return this.http.post<DatasetID>(url, payload);
  }

  getDataset(id: string) {
    const url = this.configService.config['domain'] + env.dataset + '/' + id;
    return this.http.get<DatasetID>(url);
  }

  addFilesDataSet(payload: Partial<IngressFiles>[]) {
    const url = this.configService.config['domain'] + env.addFiles;
    return this.http.post<Partial<IngressFiles>[]>(url, payload);
  }

  removeFilesDataSet(payload: IngressFiles[]) {
    const url = this.configService.config['domain'] + env.addFiles;
    return this.http.delete(url, { body: payload});
  }

  getFilesDataSet(id: string) {
    const url = this.configService.config['domain'] + env.dataset + '/' + id + '/file';
    return this.http.get<IngressFilesResponse>(url);
  }

  getIngressSchema(enclaveKey: string) {
   const url = this.configService.config['domain'] + env.ingressSchemaApi + '/' + enclaveKey;    
    return this.http.get<SchemaResponse>(url);
  }

  getsFTPDetails(email: string) {
    const url = this.configService.config['domain'] + env.sfTPDetails;
    const params = {
      "userEmail": email
    }
    return this.http.get<any>(url, { params: params });
  }

  getsFTPURL(studyKey: string, resourceKey: string) {
    const url = this.configService.config['domain'] + env.sFTPURL
    const params = {
      "studyKey": studyKey,
      'resourceKey': resourceKey
    }
    return this.http.get<sFTPURL>(url, { params: params });
  }


  getMetaDataDetail(datasetId : string | null) {
    const url = this.configService.config['domain'] + env.dataIngressGetMetadata + '/' + datasetId + '/metadata';
    return this.http.get<MetaDataResponse>(url);
  }

  createMetaData(payload:MetaDataPayLoad) {
    const url = this.configService.config['domain'] +env.saveIngress;
    return this.http.post<MetaDataResponse>(url, payload);
  }

  anaylyticsInstances(id:string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/ondemand/instance";
    return this.http.get<IngressResponse>(url);
  }

  inviteNewMember(payload:InviteNewMemberPayload){
    const url = this.configService.config['domain'] + env.inviteNewMemberApi;
    return this.http.post<InviteNewMemberApi>(url, payload);
  }

  addExistingMember(payload:AddExistingMemberPayload[]){
    const url = this.configService.config['domain'] + env.inviteExistingMemberApi;
    return this.http.post<ExistingMemberApi>(url, payload);
    
  }
  getMemberDetailsByEmail(email:string){
    const url = this.configService.config['domain'] + env.memberDetailsByEmailApi;
    const params = {
      "userEmail":email
    }
    return this.http.get<IngressResponse>(url , { params: params });
  }

  viewMemberList(studyKey:string,size:number,nextToken:string){
    const url = this.configService.config['domain'] + env.viewMemberApi;
    const params = {
      "studyKey":studyKey,
      "size":size,
      "nextToken":nextToken
    }
    return this.http.get<MemberListApi>(url,{ params: params });
  }

  editMember(payload:EditMemberPayload){
    const url = this.configService.config['domain'] + env.editMemberApi;
    return this.http.put<EditMemberApi>(url, payload);
    
  }

  getMembersDetailList(studyKey:string){
    const url = this.configService.config['domain']  + env.membersListApi + "/" + studyKey;
    return this.http.get<StudyBaseMemberListApi>(url);
  }

  removeMember(id:{id:string}){
    const url = this.configService.config['domain'] + env.editMemberApi;
    return this.http.delete<DeleteMemberApi>(url, { body: id});
    
  }
  getexcludedCurrentRoleList(roleName:string){
    const url = this.configService.config['domain'] + env.excludeCurrentroleApi;
    const params = {
      "roleName":roleName
    }
    return this.http.get<EditRoleApi>(url,{ params: params });
  }

  getRoleListByRoleType(roleType:string){
    const url = this.configService.config['domain'] + env.getRoleByType +'/'+roleType;
    return this.http.get<EditRoleApi>(url);
  }



  getSagmakerUserProfile(id:string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/sagemakeruserprofile"; 
    return this.http.get<SageMakerResponse>(url);
  }

  getCloud9(id:string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/cloud9"; 
    return this.http.get<SageMakerResponse>(url);
  }

  getInfraDetail(id: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/infradetail"; 
    return this.http.get<InfraDetailsResponse>(url);
    
  }

  gethpcInstanceSize() {
    const url = this.configService.config['domain'] + env.instarnceSize+"/hpcInstanceSize/contents"; 
    return this.http.get<HTCFileSize>(url);
  }

  getec2InstanceSize() {
    const url = this.configService.config['domain'] + env.instarnceSize+"/ec2InstanceSize/contents"; 
    return this.http.get<EC2InstanceSize>(url);
  }

  
  gethjupyterInstanceSize() {
    const url = this.configService.config['domain'] + env.instarnceSize+"/jupyterInstanceSize/contents"; 
    return this.http.get<GetJupyterInstance>(url);
  }
 
  getPosit(id: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + id + "/analytics/positurl";
    return this.http.get<PositDetailsResponse>(url);
  }

  getStudyBucket(id: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+id+"/analytics/studybucketdetails"; 
    return this.http.get<BucketDetailsResponse>(url);
  }

  saveJupyterInstance(payload: JupyterInstancePayload) {
    const url = this.configService.config['domain'] +env.analyticsInstance + "/analytics/jupyternotebook";
    return this.http.post<MetaDataResponse>(url, payload);
  }

  saveEc2Instance(payload: JupyterInstancePayload) {
    const url = this.configService.config['domain'] +env.analyticsInstance + "/analytics/ec2instance";
    return this.http.post<MetaDataResponse>(url, payload);
  }

  getJupterInstance(instanceId: string, studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/"+studyKey+"/analytics/launch/jupyternotebook/"+instanceId; 
    window.open(url, '_blank');
  }

  getEc2Instance(instanceId: string, studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/launch/ec2instance/" + instanceId; 
    window.open(url, '_blank');
  }

  getGenerateKey(studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/cloud9/credentials";
    return this.http.get<GenerateKey>(url);
  }

  postNftLogin(email: NftPayLoad) {
    const url = this.configService.config['domain'] + env.nftLogin;
    return this.http.post<cloud9Response>(url, email);
  }
  
  getLaunchSageMaker(studyKey: string, appName: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/launch/"+appName+"/sagemakeruserprofile";
    window.open(url, '_blank');
  }

  saveSageMakerProfile(payload: SageMakerProfile) {
    const url = this.configService.config['domain'] + env.analyticsInstance +"/analytics/sagemakeruserprofile";
    return this.http.post<SageMakerResponse>(url, payload);
  }

  terminateInstance(studyKey: string, instanceId : string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/instance/" + instanceId;
    return this.http.delete<SageMakerResponse>(url);
  }

  saveCloud9(payload: SageMakerProfile){
    const url = this.configService.config['domain'] + env.analyticsInstance +"/analytics/cloud9";
    return this.http.post<SageMakerResponse>(url, payload);
  }

  getCloud9Launch(studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/launch/cloud9";
    window.open(url, '_blank');
  }

  getHpcInstanceDetails(studyKey: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/analytics/hpcinstancedetails/" + studyKey;
    return this.http.get<HTCFNodeInstance>(url);
  }

  getHpcLaunch(studyKey: string, instanceId:string) {
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/analytics/launch/hpc/"+instanceId;
    window.open(url, '_blank');
  }

  submitMetadata(payload:any){
    const url = this.configService.config['domain'] + 'enclave-base-data-ingestion-service/api/v1/dataset/submit'
    return this.http.post<any>(url, payload);

  }

  saveHPCData(studyKey: string, instanceId: string, action:string) {
    let payload = {};
    const url = this.configService.config['domain'] + env.analyticsInstance + "/" + studyKey + "/manageInstance/"+action+"/"+ instanceId ;
    return this.http.post<any>(url, payload);
  }

  getAllInstanceSizeList(instanceType: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/instancetypes/"+instanceType; 
    return this.http.get<HTCFileSize>(url);
  }

  getHpcInformation(instanceId: string) {
    const url = this.configService.config['domain'] + env.analyticsInstance+"/hpc/info/"+instanceId; 
    return this.http.get<any>(url);
  }

}